@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body,
#__next {
  @apply h-full;
}

 /* Following CSS is exclusive for firefox browser,
    as it has isue with display:-webkit-box css which
    is applied with tailwind line-clamp plugin
    so to avoid mal-function it's the solution
    */
@-moz-document url-prefix() {
  [class*="line-clamp-"]:not(.line-clamp-none) * {
    display: inline !important;
  }
}
